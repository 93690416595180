.top-picks-mobile-section {
    padding: 15px 10px 10px;
    background: #fff;

    .top-picks-content {
        overflow: auto;
        white-space: nowrap;
        margin-right: -12px;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }

    .top-picks-box {
        text-align: center;
    }

    .top-pickshe-card {
        text-align: center;
        width: 30%;
        display: inline-block;
        margin: 0px 6px 15px 0;
        vertical-align: top;

        figure {
            display: block;
            overflow: hidden;
            position: relative;
            margin-bottom: 5px;

            &:before {
                content: '';
                display: block;
                padding-top: 86%;
            }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        .category-name {
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: #333;
            font-weight: normal;
            font-family: "pangramregular";
            text-transform: capitalize;
            transition: 0.3s all ease-in-out;
        }

        &:hover {
            .category-name {
                color: #E27A34;
            }

        }
    }
}